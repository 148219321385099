@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    .nav-top li {
        @apply px-3 font-medium md:text-sm hover:border-b-4 hover:border-accentOrange hover:pb-3 xs:text-sm text-accentOrange
    }

    button {
        @apply bg-[#690228] text-white flex gap-3 items-center px-4 py-2 rounded-xl
    }
}

.homebtn {
    box-shadow: 3px -4px 8px rgb(255 200 39 / 30%);
}

.headSpan {
    text-shadow: 3px -4px 8px rgb(255 200 39 / 30%);
    color: #fd9223 !important;
}

.game-card {
    box-shadow: 3px -4px 8px rgb(255 200 39 / 30%);
}

*,
html,
body {
    scroll-behavior: smooth;
}

.right-font {
    line-height: normal;
    margin-bottom: 0;
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-weight: 700;
}